import styled from 'styled-components';

export const AppStyled = styled.div`
    text-align: center;
    position: relative;
    font-weight: 900;

    header {
        background: url('/assets/main.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        opacity: 0.25;
    }

    img {
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    div {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 200px;
        pointer-events: none;
    }

    span {
        font-family: "Pacifico", cursive;
        font-size: 26px;
    }
    
`;